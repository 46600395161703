<template>
    <loading v-if="!display"/>
    <layout-store
        v-else
        pageTitle=""
        active=""
        backPath=""
        :hideBackButton="true"
        hideFooterBackground
    >
        <loading v-if="loading"/>
        <div class="body">
            <div class="form-title"></div>
              <div class="finish-text">
                <div v-if="is_success">
                    ยืนยันได้รับสิทธิ์เรียบร้อยแล้ว
                </div>     
                <div v-else>
                    ไม่พบข้อมูลในระบบ
                    <br>
                    หรือคิวอาร์โค้ดถูกใช้ไปแล้ว
                </div>
            </div>  
        </div>
        <div class="footer">
            <!-- <BaseButton
                label="ถัดไป"
                @onClick="onSubmit"
                full
                :disabled="true"
            /> -->
            <div class="btn-fix-bottom">
                <div class="btn-next">
                    <img src="@/assets/store/images/btn-finish-active.png" alt="" width="220"   @click="onSubmit"  srcset="">
                </div>
            </div>
            
        </div>
    
    </layout-store>
</template>
<script>
export default {
    data() {
        const userProfile = this.$cookies.get("LINE_LIFF_DATA")
        const userData = this.$cookies.get("USER_DATA")
        return {
            userProfile,
            loading: true,
            display: false,
            buttonDirty: false,
            formDirty: false,
            formData: {},
            is_success : true
        }
    },mounted() {
        if (this.$route.query.status) {
            if (this.$route.query.status === "UPDATE_SUCCESS") {
                this.is_success = true
            } else {
                this.is_success = false
            }
            this.display = true
        } else {
            this.$router.push('/404')

        }
        this.loading = false
    },
    methods:{
        onSubmit() {
            this.$router.push('/admin/use-coupon')
        }
    }
}
</script>
<style scoped>
.finish-text {
      font-family: 'DB Helvethaica X 77 BdCond';
    text-align: center;
    font-size: 34px;
    color: #1c4287;
    font-weight: 900;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
</style>