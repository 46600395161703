<template>
    <loading v-if="!display"/>
    <layout-user
        v-else
        pageTitle=""
        active="register"
        backPath="/"
        hideFooterBackground
        :hideBackButton="true"
    >
        <loading v-if="loading"/>
        <div class="body">
            <!-- <div class="logo-alcon">
                <img src="@/assets/images/Alcon Logo.png" alt="">
                <div>
                    คู่ที่ใช่ ยังไงก็ Alcon
                </div>
            </div> -->
            <div class="line-display-img">
                <div class="img-profile">
                    <img :src="userProfile.pictureUrl" class="w-100 radius-100" alt="" srcset="">
                </div>
            </div>
            <div class="form-title fc-2">สวัสดี 
                <div class="font-name-res">{{userProfile.displayName}}</div>
                </div>
            <div class="form-register">
                <div class="form-input">
                    <div class="form-label">ชื่อ</div>
                    <base-input
                        v-model="formData.first_name"
                        placeholder=""
                        type="text"
                        
                    />
                </div>
                <div class="form-input">
                    <div class="form-label">นามสกุล</div>
                    <base-input
                        v-model="formData.last_name"
                        placeholder=""
                        type="text"
                
                    />
                </div>
                <div class="form-input">
                    <div class="form-label">เบอร์โทรศัพท์</div>
                    <base-input
                        v-model="formData.phone_number"
                        placeholder=""
                        type="tel"
                        maxlength="10"
                        errorMessage="เบอร์โทรศัพท์ไม่ถูกต้อง"
                        :error="formDirty && validate.is_phone_number_valid"
                        @onKeypress="onNumberInput"
                    />
                </div>
                <div class="form-input">
                    <div class="form-label">E-mail</div>
                    <base-input
                        v-model="formData.email"
                        placeholder=""
                        type="email"
                        errorMessage="รูปแบบอีเมลไม่ถูกต้อง"
                        :error="formDirty && validate.is_email_valid"
                     
                       
                    />
                </div>
                <!-- <div class="form-input">
                    
                    <div class="form-label">รหัสยืนยันตัวตน (OTP)</div>
                    <div class="row">
                        <div class="col-6">
                              <base-input
                                v-model="formData.otp"
                                placeholder="กรอกรหัสยืนยันตัวตน"
                                type="tel"
                                maxlength="6"
                                errorMessage="รหัสยืนยันตัวตนไม่ถูกต้อง"
                                :error="formDirty && validate.is_otp_valid"
                                @onKeypress="onNumberInput"
                            />
                        </div>
                            <div class="col-6">
                                <BaseButton
                                    :label="text_otp"
                                    @onClick="onSubmitOTP"
                                    full
                                    :disabled="validate.is_phone_number_valid || text_otp !== 'กดรับ OTP'"
                                />
                            </div>
                             <div class="container" style="display:none;"  id="sign-in-button"></div>
                    </div>
                  
                        
                    
                </div> -->
                <!-- <div class="form-input">
                    <v-checkbox v-model="formData.agree_term_condition" class="term-condition">
                        <template v-slot:label>
                            <div class="term-condition-label">
                                เงือนไขข้อตกลง
                                <a @click="showTermCondition = true">
                                    เงื่อนไขการใช้งานและนโยบายความเป็นส่วนตัว
                                </a>
                            </div>
                        </template>
                    </v-checkbox> -->
                    <label class="container-checkbox"> <span class="text-term-label">เงื่อนไขและข้อตกลง</span> 
                        <input type="checkbox" checked="checked"  v-model="formData.agree_term_condition" />
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
     
        <div class="footer">
            <!-- <BaseButton
                label="ถัดไป"
                @onClick="onSubmit"
                full
                :disabled="!validate.is_empty || buttonDirty"
            /> -->
            <div class="btn-register">
                <img v-if="!validate.is_empty || buttonDirty"  src="@/assets/images/btn-register-disable.png" class="img-btn-register btn-w250" alt="">
                <img v-else src="@/assets/images/btn-register-active.png" @click="onSubmit()" class="img-btn-register btn-w250" alt="">
                <img src="@/assets/images/talent.png" class="talent" alt="">
            </div>
        </div>
        <Modal :visible="showDialog">
            <v-card class="pa-4 text-center">
                <div class="mb-1 font-weight-2 font-size-4 mb-4 fc-1 modal-text-detail" >{{messageError}}</div>
                 <div class="btn-close-modal" @click="onDialogSubmit">
                  ตกลง
              </div>
            </v-card>
        </Modal>
         <Modal :visible="showDialog2">
            <v-card class="pa-4 text-center">
                <div class="text-center">
                    <svg width="110" height="98" viewBox="0 0 110 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M51.941 5.57907C53.3206 3.45061 56.4355 3.45062 57.8151 5.57907L97.9815 67.5488C99.4908 69.8775 97.8194 72.9525 95.0445 72.9525H14.7116C11.9367 72.9525 10.2653 69.8775 11.7746 67.5488L51.941 5.57907Z" stroke="black"/>
                    <path d="M54.878 32.2992C44.5215 32.2992 36.1163 39.7862 36.1163 49.0112C36.1163 58.2362 44.5215 65.7232 54.878 65.7232C65.2345 65.7232 73.6397 58.2362 73.6397 49.0112C73.6397 39.7862 65.2345 32.2992 54.878 32.2992ZM56.7542 57.3672H53.0018V54.0248H56.7542V57.3672ZM56.7542 50.6824H53.0018V40.6552H56.7542V50.6824Z" fill="black"/>
                    </svg>
                </div>
                <div class="mb-1 font-weight-2 font-size-4 mb-4">ไม่พบข้อมูลผู้ใช้บริการ</div>
                 <div class="mb-1 font-weight-2 font-size-4 mb-4">กรุณากรอกข้อมูลให้ถูกต้อง</div>
                <BaseButton
                    class="px-6"
                    label="กลับหน้าลงทะเบียน"
                    @onClick="onDialogSubmit2"
                />
            </v-card>
        </Modal>
        <Modal :visible="showTermCondition">
            <v-card class="term-condition-modal">
                <v-card-title class="grey lighten-2 font-size-6">
                    เงื่อนไขการใช้งานและ<br>นโยบายความเป็นส่วนตัว
                </v-card-title>

                <v-card-text class="pt-4">

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="showTermCondition = false, formData.agree_term_condition = true"
                    >
                        ยอมรับ
                    </v-btn>
                </v-card-actions>
            </v-card>
        </Modal>
    </layout-user>
</template>

<script>
import { getUserProfileAPI, updateUserProfileAPI, registerAPI} from '@/apis/user/register'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber   } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCHLLRt2WtbKmnKcc6hYnWXgVGr5T6S1Nk",
  authDomain: "app-loreal.firebaseapp.com",
  projectId: "app-loreal",
  storageBucket: "app-loreal.appspot.com",
  messagingSenderId: "444657527882",
  appId: "1:444657527882:web:b6bc75a5822faad2080ab3",
  measurementId: "G-XL81381D3X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


export default {
    data() {
        const userProfile = this.$cookies.get("LINE_LIFF_DATA")
        const userData = this.$cookies.get("USER_DATA")
        return {
            userData,
            userProfile,
            loading: true,
            display: true,
            buttonDirty: false,
            formDirty: false,
            formData: {
                line_user_id:userProfile.userId,
                line_display_name:userProfile.displayName,
                line_display_image:userProfile.pictureUrl,
                first_name:"",
                last_name:"",
                phone_number:"",
                email:"",
                agree_term_condition: false,
                is_questionnaire:false,
                questionnaire_1:"",
                questionnaire_2:"",
                questionnaire_3:""
            },

            validate: {
                is_empty: false,
                is_phone_number_valid: false,
                is_email_valid:false
                // is_otp_valid:false
                // is_cid_valid: false,
            },
            showDialog: false,
            showDialog2: false,
            messageError: '',
            showTermCondition: false,
            text_otp:'กดรับ OTP',
            pass: "",
            changpass: "ขอรหัสผ่านใหม่อีกครั้ง",
            errpass: "รหัสผ่านแบบใช้ครั้งเดียวไม่ถูกต้อง",
            timer: "00:00",
            interval: "",
            reNew: true,
            value: true,
            phoneNumber: null,
            confirmationResult: null,
            otpnum: null,
            recaptchaVerifier: null,
            recaptchaWidgetId: null,
            confirmResult: null,
            smsSent: false,
            otp: "",
            tel: " ",
            tel01: " ",
            tel02: " ",
            tel03: " ",
            focus: true,
            errors: [],
            verifyCodeOTP:null,
            verifyData:null
        }
    },
    mounted() {
        // gtag('config', 'G-VP4CPS5MT6', {
        //     page_title: 'ลงทะเบียน'
        // })

        console.log("this is register form")
        this.display = true
        this.loading = false
        // this.onLoad()
    },
    watch: {
        'formData': {
            handler(val){
                if (!val.first_name ||
                    !val.last_name ||
                    !val.phone_number ||
                    !val.email ||
                    !val.agree_term_condition) {
                    this.validate.is_empty = false
                }  else {
                    this.validate.is_empty = true
                }

                if (val.phone_number.length !== 10) {
                    
                    this.validate.is_phone_number_valid = true
                    this.messageError = 'เบอร์โทรศัพท์ไม่ถูกต้อง'
                }
                else {
                    this.validate.is_phone_number_valid = false
                }
                if (!this.validateEmail(val.email)) {
                    this.validate.is_email_valid = true
                    this.messageError = 'รูปแบบอีเมลไม่ถูกต้อง'
                }
                else {
                    this.validate.is_email_valid = false
                }
                
            },
            deep: true
        },
    },
    methods: {
        async onLoad() {

            const auth = getAuth();
                window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
                'size': 'invisible',
                'callback': (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    onSignInSubmit();
                }
                }, auth);
            const appVerifier = window.recaptchaVerifier;
            this.recaptchaVerifier = appVerifier
         
            this.display = true
            this.loading = false
       
        },
        async onSubmit() {
            // this.formDirty = true
            // this.loading = true
            // this.buttonDirty = true
            if(this.validate.is_phone_number_valid) {
                this.showDialog = true
                this.loading = false
                this.buttonDirty = false
            } else if(this.validate.is_email_valid) {
                this.showDialog = true
                this.loading = false
                this.buttonDirty = false
            }
            else {
                const formData = {
                    ...this.formData
                }
                await registerAPI({
                        payload: formData,
                        cbSuccess : async res => {
                            if(res.data.status ==='CREATE_SUCCESS') {
                                this.$router.push('/')
                            } else if (res.data.status === 'PHONE_DUPLICATE_ERROR') {
                                this.messageError = 'เบอร์โทรศัพท์ซ้ำ ไม่สามารถลงทะเบียนได้'
                                this.showDialog = true
                                this.loading = false
                            }  else if (res.data.status === 'MID_DUPLICATE_ERROR') {
                                 this.messageError = 'บัญชีนี้ลงทะเบียนแล่ว'
                                this.showDialog = true
                                this.loading = false
                            } else {
                                this.messageError = 'ทำรายการไม่สำเร็จ'
                                this.showDialog = true
                                this.loading = false
                            }
                            
                        } ,
                        cbError : (e) => {
                            console.log('e', e)
                        }
                    })                    
                
            }
        },
        async onSubmitOTP() {
            console.log(this.formData.phone_number)
            if (this.formData.phone_number !== "" && this.formData.phone_number.length >= 9) {
                   this.submitSMS(this.formData.phone_number)
           

            } else {
                this.messageError = 'กรุณาระบุเบอร์มือถือ'
                this.showDialog = true
            }
         
        },   
        async checkBaData (phone_number) {
            await checkBADataAPI({
                phone_number: phone_number,
                cbSuccess : async res => {
                    if(res.data.data) {
                       this.verifyData = true
                    } else {
                        this.verifyData = false
                        this.showDialog2 = true
                        this.loading = false
                    }
                    
                } ,
                cbError : (e) => {
                    console.log('e', e)
                }
            })
        },
        onNumberInput(event) {
            const key = window.event ? event.keyCode : event.which
            if (event.keyCode === 8 || event.keyCode === 46) {
		        return true
            }
            else if ( key < 48 || key > 57 ) {
               event.preventDefault()
            }
            else {
                return true
		    }
        },
        onDialogSubmit() {
            this.showDialog = false
            this.buttonDirty = false
        },
        onDialogSubmit2() {
            this.showDialog2 = false
            this.buttonDirty = false
        },
        async submitSMS(value) {
            var phoneNumber = "+66" + value.substring(1, 10);
            const appVerifier = this.recaptchaVerifier;
            const auth = getAuth();
            signInWithPhoneNumber(auth, phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    this.confirmationResult = confirmationResult;
                    // ...
                    this.countDown(300);
                }).catch((error) => {
                    console.log("ERROR")
                    console.log(error)
                });
    
        },
        verifyCode(code) {
            if (this.confirmationResult) {
                 this.confirmationResult.
                confirm(code).then((result) => {
                // User signed in successfully.
                    this.verifyCodeOTP = true
                    console.log(result)
                // ...
                }).catch((error) => {
                    console.log(error)
                    this.verifyCodeOTP = false
                        this.showDialog = true
                         this.verifyCodeOTP = false
                        this.messageError = "รหัสยืนยันตัวตนไม่ถูกต้อง"
                        this.loading = false
                // User couldn't sign in (bad verification code?)
                // ...
                });
            } else {

                this.showDialog = true
                this.messageError = "รหัสยืนยันตัวตนไม่ถูกต้อง"
                this.loading = false
            }
           
        },
        countDown(duration) {
            let timer = duration,
                minutes,
                seconds;
                this.interval = setInterval(() => {
                    minutes = parseInt(timer / 60, 10);
                    seconds = parseInt(timer % 60, 10);

                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    seconds = seconds < 10 ? "0" + seconds : seconds;

                    this.timer = minutes + ":" + seconds;
                    this.reNew = false;
                     this.text_otp =   this.timer 
                    if (--timer < 0) {
                    timer = duration;
                    clearInterval(this.interval);
                    this.reNew === true;
                    this.changpass = "ขอรหัสผ่านใหม่อีกครั้ง";
                    this.text_otp = "กดรับ OTP"
                    this.timer = "";
                    }
            }, 1000);
        
        },
        validateEmail(email){
			var re = /\S+@\S+\.\S+/;
			return re.test(email);
		},
    },
}
</script>

<style scoped>
.content-wrap {
    height: 100%;
    overflow: auto;   
    position: relative;
    box-shadow: 0px 0px 10px grey;
    background-image: url('../../../assets/images/REGISTRATION-FORM_DB.png');
    /* background-image: url(/img/bg.c4154c07.png); */
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #082257;    
}
.body {
    flex: 1;
    padding: 30px 65px 0;
    z-index: 10;
}
.footer {
    padding: 16px 32px;
    z-index: 10;
    position: relative;
}
.form-title {
        font-size: 30px;
    font-family: 'DB Helvethaica X 67 MedCond';
    font-weight: 100;
    color: #002F87;
    text-align: center;
    margin-bottom: 0px;
    text-shadow: 1px 1px 8px #1e4489;
    width: 120%;
    text-overflow: ellipsis;
    /* overflow: hidden; */
    white-space: nowrap;
    margin-left: -10%;
    margin-right: -10%;
}
.form-input {
    margin-bottom: 10px;
}
.form-input:last-child {
    margin-bottom: 0;
}
.form-label {
    font-family: 'DB Helvethaica X 67 MedCond';
    font-size: 30px;
    font-weight: 400;
    color: #F0F6FF;
    margin-bottom: 4px;
    text-shadow: 1px 1px 8px #1e4489;
}
.gender-select {
    display: flex;
}
.gender-button {
    width: 50%;
    margin-right: 16px;
    border-radius: 8px;
    border: 1px solid #002F87;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 80px;
    font-size: 14px;
    font-weight: 400;
    color: #002F87;
    padding: 8px;
    transition: background-color 0.5s ease;
}
.gender-button:last-child {
    margin-right: 0;
}
.gender-active {
    font-weight: 700;
    color: #FFFFFF;
    background-color: #002F87;
}
.term-condition-label {
    font-size: 24px;
    line-height: 1.5;
    color: #F0F6FF;
}
.term-condition-label a {
    text-decoration: underline;
}
.term-condition-modal .v-card__title {
    font-family: 'chulabhornlikittext', sans-serif !important;
    color: #000000;
}
.term-condition-modal p {
    text-indent: 30px;
    color: rgba(0, 0, 0, 0.75);
}
.term-condition-modal strong {
    color: #000000;
}
.term-condition-modal .v-card__text > strong {
    margin-bottom: 8px;
    display: inline-block;
}
.term-condition-modal ol {
    margin-bottom: 16px;
}
.term-condition-modal ol li {
    margin-bottom: 8px;
}
.radius-100 {
    border-radius: 100%;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 20%);
}

.img-profile {
    width: 135px;
    margin: 0 auto;
}
.logo-alcon {
        margin-top:calc(var(--vh, 1vh) * -20);
    text-align: center;
    color: #FFF;
    font-size: 34px;
}
.btn-register{
    text-align: center;
}
.img-btn-register{
    cursor: pointer;
}
.talent {
    position: absolute;
    width: 65px;
    right: 5px;
    bottom: 5px;
}
.btn-close-modal {
    /* font-size: 30px; */
    font-family: 'DB Helvethaica X 67 MedCond';
    font-weight: 100;
    background: #c7cfdb;
    color: #194ba0;
    font-size: 28px;
    cursor: pointer;
}
/* The container */
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 20px;
  width: 20px;
  background-color: #F0F6FF;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #F0F6FF;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #F0F6FF;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #1e4489;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.text-term-label {
    font-family: 'DB Helvethaica X 67 MedCond';
    font-size: 24px;
    font-weight: 100;
    color: #F0F6FF;
    margin-bottom: 4px;
    text-shadow: 1px 1px 8px #1e4489;
}
.modal-text-detail {
    padding: 20px 0;
    font-family: 'DB Helvethaica X 67 MedCond';
    font-size: 34px;
    font-weight: 400;
    color: #1e4489;
}
   .font-name-res {
           font-size: 28px !important;
    }
@media only screen and (max-width: 320px) {
  
    .font-name-res {
           font-size: 22px !important;
    }
}
@media only screen and (min-width: 1200px) {

    
}
/* iPhone 13 mini */
@media only screen
    and (device-width: 375px)
    and (device-height: 812px)
    and (-webkit-device-pixel-ratio: 3) {
   
        .font-name-res {
            font-size: 26px !important;
        }
    }
/* iPhone 13 and iPhone 13 Pro */
@media only screen
    and (device-width: 390px)
    and (device-height: 844px)
    and (-webkit-device-pixel-ratio: 3) {
    
    }
/* iPhone 13 Pro Max */
@media only screen
    and (device-width: 428px)
    and (device-height: 926px)
    and (-webkit-device-pixel-ratio: 3) {

    }
/* iPhone 11 and iPhone XR */
@media only screen
    and (device-width: 414px)
    and (device-height: 896px)
    and (-webkit-device-pixel-ratio: 2) {

    }
 /* iPhone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X */
@media only screen
    and (device-width: 375px)
    and (device-height: 812px)
    and (-webkit-device-pixel-ratio: 3) {

    }
 /* iPhone 11 Pro Max and iPhone Xs Max */
@media only screen
    and (device-width: 414px)
    and (device-height: 896px)
    and (-webkit-device-pixel-ratio: 3) {
       
    }
</style>