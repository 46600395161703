<template>
    <loading v-if="!display"/>
    <div v-else>
         <loading v-if="loading"/>
        <div class="body">
            <div>
                <div class="term-title">
                     เงื่อนไขและข้อตกลง
                </div>
                  <div class="term-text">
                   ข้อกำหนด เงื่อนไข และนโยบายความเป็นส่วนตัว 
สำหรับเข้าร่วมกิจกรรม “ลงทะเบียนเพื่อรับคูปองส่วนลดผลิตภัณฑ์ บริษัท อัลคอน แลบอราทอรีส์ (ประเทศไทย) จำกัด
<br>

<span class="ml-10"></span>1. เมื่อท่านกดยินยอมและยอมรับเงื่อนไขตามรายละเอียดด้านล่างนี้บริษัท อัลคอน แลบอราทอรีส์ (ประเทศไทย) จำกัด 
จะเก็บข้อมูลส่วนตัวของท่าน และจะส่งคูปองส่วนลดให้กับท่านผ่านทางแอพพลิเคชั่น LINE 
– รายละเอียดข้อมูลส่วนบุคคลที่ทำการเก็บได้แก่ ชื่อ , นามสกุล , หมายเลขโทรศัพท์ , อีเมล และ พฤติกรมการใช้ผลิตภัณฑ์ ของท่าน
<br>
<span class="ml-10"></span>2. ท่านยินยอมรับให้บริษัท สื่อสารกับท่านผ่านช่องทาง Line official account Alcon TH โดยบริษัทฯ สามารถแจ้ง ข้อมูล ข่าวสารทางการตลาดต่างๆ รวมถึงข้อมูลที่เกี่ยวกับผลิตภัณฑ์ของบริษัทฯ ให้กับท่านได้ ตราบเท่าที่ข้อมูลของท่านยังไม่ถูกเพิกถอนจากระบบ หรือ ตราบเท่าที่ท่านผู้เป็นเจ้าของข้อมูลยินยอม
<br>
<span class="ml-10"></span>3. ข้อมูลส่วนบุคคลของท่านที่บริษัทฯ ได้รับมา เช่น ชื่อ-นามสกุล, หมายเลขโทรศัพท์, อีเมล และ พฤติกรมการใช้ผลิตภัณฑ์ของท่าน ซึ่งสามารถบ่งบอกตัวบุคคลของท่านได้ และเป็นข้อมูลส่วนบุคคลที่มีความถูกต้องและเป็นปัจจุบัน จะถูกนำไปใช้ให้ เป็นไปตามวัตถุประสงค์การดำเนินงานของบริษัทฯ เท่านั้น และบริษัทจะดำเนินมาตรการที่เหมาะสมเพื่อคุ้มครองสิทธิของ เจ้าของข้อมูลส่วนบุคคล
<br>
<span class="ml-10"></span>4. ท่านยินยอมให้บริษัทฯ เก็บรวมรวม ใช้ และเปิดเผยข้อมูลที่ให้ไว้หรือมีอยู่ หรือที่บริษัทฯ ได้รับหรือเข้าถึงได้จากแหล่งอื่น และยินยอมให้ผู้รับข้อมูลจากบริษัทฯ เก็บรวมรวม ใช้ และเปิดเผยข้อมูลดังกล่าวได้ตามวัตถุประสงค์ที่ได้แจ้งไว้ต่อบริษัทฯ
<br>
<br>
เมื่อท่านกดยินยอมหรือยอมรับ ถือว่าท่านได้ยินยอมตามรายละเอียดด้านล่าง
 <br>
 <span class="ml-10"></span>1. ข้าพเจ้าได้อ่านและยอมรับเงื่อนไขและข้อกำหนดต่างๆ และยินยอมตามรายละเอียดเงื่อนไขและข้อตกลง
 <br>
 <span class="ml-10"></span>2. ข้าพเจ้าได้อ่านและยอมรับการแจ้งเตือนการเข้าถึง ข้อกฎหมายและนโยบายความเป็นส่วนตัว โดยยินยอมให้บริษัท สามารถนำข้อมูลของข้าพเจ้าไปใช้สำหรับกิจกรรมทางการตลาด และ ยินยอมให้บริษัท อัลคอน แลบอรอทอรีส์ (ประเทศไทย) จำกัด ติดต่อสื่อสารกับข้าพเจ้าได้ผ่านทางช่องทางต่างๆ ของ บริษัทฯซึ่งหมายรวมถึง การส่ง SMS , การแจ้งเตือน หรือนาเสนอข้อมูลสินค้า โปรโมชัน หรือข้อมูผลิตภัณฑ์ที่ท่านสนใจ ผ่านทาง Email , Facebook , Line official account , Line ads หรือช่องทางอื่นๆของทางบริษัท จนกว่า ข้าพเจ้าจะเพิกถอนสิทธิ์หรือไม่ยินยอม ให้ใช้ข้อมูลของข้าพเจ้าตามเงื่อนไขที่ระบุไว้
 <br>
 <span class="ml-10"></span>3. ข้าพเจ้ายินยอมให้บริษัทฯ ส่งข้อมูลเกี่ยวกับสิทธิประโยชน์พิเศษ และข้อมูลกิจกรรมทำงการตลาดให้ข้าพเจ้า 
<br>
<span class="ml-10"></span>4. ข้าพเจ้ายินยอมให้บริษัทฯ เข้าถึงข้อมูลส่วนตัวของข้าพเจ้าตามนโยบายการเข้าถึงข้อมูลส่วนบุคคล

                </div>
            </div>
        </div>
          <div class="footer">
            <!-- <BaseButton
                label="ถัดไป"
                @onClick="onSubmit"
                full
                :disabled="true"
            /> -->
            <div class="btn-fix-bottom">
                <div class="btn-next">
                    <img src="@/assets/store/images/agree.png" alt="" class="btn-w250"   @click="onSubmit"  srcset="">
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
export default {
    data() {
        const userProfile = this.$cookies.get("LINE_LIFF_DATA")
        const userData = this.$cookies.get("USER_DATA")
        return {
            userData,
            userProfile,
            loading: true,
            display: true,
            buttonDirty: false,
            formDirty: false,
            formData: {},
        }
    },mounted() {
        this.loading = false
    },
    methods:{
        onSubmit() {
            this.$router.push('/user/register')
        }
    }
}
</script>
<style>
.term-title {
    font-family: 'DB Helvethaica X 77 BdCond';
    text-align: center;
    font-size: 38px;
    color: #1c4287;
    font-weight: 900;
    margin-top: 25px;
    /* position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}
.ml-10{
    margin-left: 10px;
}
.term-text {

    text-align: left;
    font-size: 24px;
    color: #1c4287;
    font-family: 'DB Helvethaica X 67 MedCond';
    font-weight: 100;
    margin-top: 25px;
        padding: 0 25px;
    /* position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}
.btn-next {
      margin-top: 25px;
    text-align: center;
}
.btn-w250 {
    width: 220px;
}

</style>