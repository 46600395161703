<template>
    <loading v-if="!display"/>
    <layout-store
        v-else
        pageTitle=""
        active=""
        backPath=""
        :hideBackButton="true"
        hideFooterBackground
    >
        <loading v-if="loading"/>
        <div class="body">
            <div class="form-title"></div>
              <div class="history-text">
            
                ตรวจสอบประวัติย้อนหลัง
                <div class="border-blue"></div>
                <div class="table-wp">
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th>รหัส</th>
                            <th style="width:135px">ชื่อคูปอง</th>
                            <th>เวลา</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v,i) in tableData.rows" :key="i">
                            <td>{{v.code}}</td>
                            <td>{{v.name}}</td>
                            <td>{{dateFormat(v.use_date)}}</td>
                        </tr>
                        <!-- <tr>
                            <td>12314568</td>
                            <td>ลดกระหน่ำรับสงการ กับส่วนลด 25% กับร้านค้าที่ร่วมรายการ</td>
                            <td>DD/MM/YYYY</td>
                        </tr> -->
                    </tbody>
                </table>
            </div>
            </div>  
            
        </div>
        <div class="footer">
            <div class="btn-fix-bottom">
                <div class="btn-next">
                    <img src="@/assets/store/images/btn-back-active.png" alt="" width="220"    @click="onSubmit"  srcset="">
                </div>
            </div>
            
        </div>
    
    </layout-store>
</template>
<script>
import { couponHistoryAPI } from '@/apis/store/coupon'
import moment from 'moment'
export default {
   data() {
        const userProfile = this.$cookies.get("LINE_LIFF_DATA")
        const adminData = this.$cookies.get("ADMIN_DATA")
        return {
            adminData,
            userProfile,
            loading: true,
            display: true,
            buttonDirty: false,
            formDirty: false,
            formData: {
                sort: "",
                order: "asc",
                offset: 0,
                limit: 999999,
                is_export: false,
                filter: {
                    line_user_id:userProfile.userId,
                    start_date: "1970-01-01T00:00:00.001Z",
                    end_data: "1970-01-01T00:00:00.001Z",
                },
            },
            tableData: {
                rows: [],
                total: 0,
            },
      
        }
    },
    async mounted() {
        if (this.adminData === null) {
            this.$router.push('/')
        } else {
            
        }
        await this.getCouponList()
        this.display = true
        this.loading = false

    },
   
 
    methods: {
        async getCouponList() {
            this.loading = true;
            await couponHistoryAPI({
                payload: this.formData,
                cbSuccess: (res) => {
                    if (res.status === 200) {
                        this.tableData.rows = res.data.rows;
                        this.tableData.total = res.data.total;
                    }
                    this.loading = false;
                },
                cbError: (err) => {
                    if (err) {
                        console.log("err:", err);
                    }
                    this.loading = false;
                },
            });
        }
        ,
        onSubmit() {
            this.$router.push('/admin/use-coupon')
        },
        dateFormat(date) {
            return moment(date).format('DD/MM/YYYY')
        },
    }
}
</script>
<style>
table {
    width: 100%;
}
thead tr th {
     font-family: 'DB Helvethaica X 67 MedCond';
    font-size: 28px;
    font-weight:900;
    text-align: left;
    color: #1c4287;
}
tbody tr td {
     font-family: 'DB Helvethaica X 67 MedCond';
    font-size: 24px;
    font-weight: 400;
    text-align: left;
}
.history-text {
    font-family: 'DB Helvethaica X 77 BdCond';
    text-align: left;
    font-size: 36px;
    color: #7d8eae;
    font-weight: 900;
    padding: 0 44px;
}
.border-blue{
    border-bottom: #1c4287 2px solid;
    width: 100%;
}
</style>