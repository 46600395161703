<template>
    <loading v-if="!display"/>
    <layout-store
        v-else
        pageTitle=""
        active=""
        backPath=""
        :hideBackButton="true"
        hideFooterBackground
    >
        <loading v-if="loading"/>
        <div class="body">
            <div class="form-title"></div>
                <div class="store-text">
                    <div>
                        {{adminData.store_name}}
                    </div>     
                </div>  
            <div class="form">
                <div class="form-input relative">
                    <div class="form-label label-code">กรอกรหัส</div>
                    <base-input
                        v-model="formData.code"
                        placeholder=""
                        type="tel"
                        id="code"
                        maxlength="8"
                        @onKeypress="onNumberInput"
                        
                    />
                    <div v-if="error_code">
                        <div class="icon-close" @click="clearCode">
                            X
                        </div>
                        <div class="error-msg">
                            {{messageError}}
                        </div>
                    </div>
                   
                </div>
                <div class="or-text">
                    หรือ
                </div>
                <div class="form-input">
                   <button class="buttom-scan" @click="scanQR()">
                       กดเพื่อสแกนคิวอาร์โค้ด
                   </button>
                </div>
                   <div class="form-input">
                    <div class="link-history" @click="goTo(`/admin/history`)">
                        ตรวจสอบประวัติย้อนหลัง
                    </div>
                    </div>
            </div>
            <div id="result"></div>
        </div>
        <div class="footer">
            <!-- <BaseButton
                label="ถัดไป"
                @onClick="onSubmit"
                full
                :disabled="true"
            /> -->
            <div class="btn-fix-bottom-note">
                <br><br>
                <div class="btn-next">
                    <img src="@/assets/store/images/btn-next-active.png" alt="" width="220"    @click="onSubmit"  srcset="">
                </div>
            </div>
            
        </div>
        <Modal :visible="showDialog">
            <v-card class="pa-4 text-center">
                <div class="mb-1 font-weight-2 font-size-4 mb-4 fc-1 modal-text-detail" >{{messageError}}</div>
                 <div class="btn-close-modal" @click="onDialogSubmit">
                  ตกลง
              </div>
            </v-card>
        </Modal>
    </layout-store>
</template>
<script>
import { userCouponQRCCODE } from '../../../apis/store/coupon'
export default {
    data() {
        const userProfile = this.$cookies.get("LINE_LIFF_DATA")
        const userData = this.$cookies.get("USER_DATA")
        const adminData = this.$cookies.get("ADMIN_DATA")
        return {
            adminData,
            userProfile,
            loading: true,
            display: false,
            buttonDirty: false,
            formDirty: false,
            formData: {
                code:"",
                phone_number:""
            },
            showDialog:false,
            messageError:"",
            error_code:false,
            liffId: process.env.VUE_APP_LINE_LIFF_ID,
            error:null,

        }
    },
    async mounted() {
        if (this.adminData === null) {
            this.$router.push(`/404`)
        }

        if (this.$route.query.qrCode) {
             this.formData.code = await this.$route.query.qrCode
            await  this.useCoupon()
        }
        this.display = true
        this.loading = false
    },
    methods:{
        async onSubmit() {
             //CALL API USE COUPON
             console.log('CALL API USE COUPON');
             if (this.formData.code === "") {
                    this.messageError = "รหัสคูปองไม่ถูกต้อง"
                    this.error_code = true
             } else {
                this.useCoupon()
             }
            
        },
        async getCoupon(code) {

        },
        async useCoupon() {
            this.loading = true
                this.display = false
           var payload2 = {
                    code:this.formData.code,
                    line_user_id: this.userProfile.userId,
                    line_display_name: this.userProfile.displayName,
                    line_display_image: this.userProfile.pictureUrl,
                    admin_id: this.adminData.id,
                    store_code: this.adminData.store_code,
                    store_id: this.adminData.store_id,
                    store_name: this.adminData.store_name,
                    is_use:true
                }
                // this.error = payload2
                //               this.alertMessage(JSON.stringify(payload))
                // //  liff.sendMessages([
                // //     {
                // //         type: "text",
                // //         text:payload
                // //     },
                // // ])
            await userCouponQRCCODE({
                payload: payload2,
                cbSuccess : async res => { 
                    if (res.data.status === 'UPDATE_SUCCESS') {
                        this.$router.push(`/admin/finish?status=UPDATE_SUCCESS`)
                        
                    } else {
                        this.$router.push(`/admin/finish?status=error`)
                    }
                } ,
                cbError : (e) => {
                    console.log('e', e)
                    // this.$router.push(`/admin/finish?status=error`)
                    // this.alertMessage(JSON.stringify(e))
                    this.loading = false
                }
            })
             this.display = true
        },
        goTo(link) {
            console.log(123);
            this.$router.push(link)
        },
        clearCode() {
            this.error_code = false
            this.messageError = ""
            this.formData.code = ""
        },
        onDialogSubmit() {
            this.showDialog = false
            this.buttonDirty = false
        },
        alertMessage(e) {
            alert(e)
        },
        onNumberInput(event) {
            const key = window.event ? event.keyCode : event.which
            if (event.keyCode === 8 || event.keyCode === 46) {
		        return true
            }
            else if ( key < 48 || key > 57 ) {
               event.preventDefault()
            }
            else {
                return true
		    }
        },
        async scanQR() {
          window.location = "https://line.me/R/nv/QRCodeReader"
        
        }
    }
}
</script>
<style>
.relative {
    position: relative;
}
.btn-close-modal {
    font-family: 'DB Helvethaica X 67 MedCond';
    font-weight: 100;
    background: #c7cfdb;
    color: #194ba0;
    font-size: 28px;
    cursor: pointer;
}
.modal-text-detail {
    padding: 20px 0;
    font-family: 'DB Helvethaica X 67 MedCond';
    font-size: 34px;
    font-weight: 400;
    color: #1e4489;
}
.store-text {
    font-family: 'DB Helvethaica X 77 BdCond';
    text-align: center;
    font-size: 36px;
    color: #1c4287;
    font-weight: 900;
    margin-bottom: 25px;
        padding: 40px;
        white-space: pre-wrap;

}
.or-text {
       font-family: 'DB Helvethaica X 77 BdCond';
    text-align: center;
    font-size: 32px;
    color: #1c4287;
    font-weight: 900;
    margin-top: 25px;
}
.form {
        padding: 0px 44px;
}
.label-code {
    font-family: 'DB Helvethaica X 77 BdCond';
    font-size: 32px;
    color: #1c4287;

}
.buttom-scan {
    font-family: 'DB Helvethaica X 77 BdCond';
    font-size: 28px;
    background: #1c4287;
    color: #FFF;
    width: 100%;
    text-align: center;
    border-radius: 8px;
    box-shadow: 1px 3px 5px #f5f5f5;
    cursor: pointer;
    margin-top: 25px;
}
.link-history{
    font-family: 'DB Helvethaica X 77 BdCond';
    font-size: 28px;
    /* background: #1c4287; */
    color: #1c4287;
    width: 100%;
    text-align: center;
    margin-top: 25px;
}
#code {
    font-family: 'DB Helvethaica X 77 BdCond';
    font-size: 32px;
    width: 100%;
    height: 40px;
    border: 1px solid #f0f6ff;
    background-color: #f0f6ff;
    border-radius: 8px;
    font-weight: 700;
    color: #1c4287;
    /* padding: 0 16px; */
    line-height: 40px;
    text-indent: 1px;
    box-shadow: 1px 3px 5px #3865a340;
}
.error-msg{
       font-family: 'DB Helvethaica X 67 MedCond';
    font-size: 20px;
    font-weight: 100;
    color: red;
    position: absolute;
    top: 90px;
    left: 12px;
}
.icon-close{
    font-family: 'DB Helvethaica X 77 BdCond';
    position: absolute;
    right: 15px;
    top: 50px;
    font-size: 25px;
    color: red;
    cursor: pointer;
}
</style>