<template>
    <loading v-if="!display" />
    <layout-user v-else pageTitle="" active="Coupon Detail" backPath="/" hideFooterBackground :hideBackButton="true">
        <loading v-if="loading" />
        <div class="body">
            <div class="line-display-img2">
                <div class="img-profile2">
                    <img :src="userProfile.pictureUrl" class="w-100 radius-100" alt="" srcset="" />
                </div>
                <div class="name-profile2">
                    สวัสดี
                    <div>{{ userProfile.displayName }}</div>
                </div>
            </div>
            <div class="vhpage"></div>
            <div class="form-title fc-2">My Coupon</div>
            <div class="mg-top-15"></div>
            <div class="form" id="form-gradient" v-if="formData !== null">
                <div class="coupon-image">
                    <img :src="formData.image_url" class="coupon-img" alt="" />
                    <div class="mg-top-15"></div>
                    <div class="coupon-expire-date">ใช้ได้ตั้งแต่วันที่ {{ dateFormat(formData.start_date) }} - {{ dateFormat(formData.expire_date) }} {{dateYear(formData.expire_date)}}</div>
                    <div class="coupon-detail">
                        <div class="fs-22">รายละเอียด</div>
                        <div v-html="handleNewLine(formData.detail)"></div>
                    </div>
                    <div class="coupon-condition">
                        <div class="fs-22">เงื่อนไขการใช้</div>
                        <div v-html="handleNewLine(formData.condition)"></div>
                    </div>
                    <img src="@/assets/images/talent2.png" class="talent2" alt="" />
                </div>
                <br /><br /><br />
                <div class="btn-flex">
                    <div class="btn-store btn-50per">
                        <img @click="$router.push(`/user/storelist`)" src="@/assets/images/store.png" class="img-btn-register" alt="" />
                    </div>
                    <div class="btn-use btn-50per">
                        <img
                            v-if="!formData.is_expire && !formData.is_use"
                            @click="modalShow"
                            src="@/assets/images/ButtonUseCoupo.png"
                            class="img-btn-register"
                            alt=""
                        />
                        <img v-else src="@/assets/images/ButtonUseCoupo2.png" class="img-btn-register btn-w250" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div class="footer"></div>
        <Modal :visible="showModal">
            <v-card class="pa-4 text-center">
                <div class="tab">
                    <div class="tab-nav">
                        <div id="tab-1" class="tab-nav-item" :class="tab === 1 ? 'tab-active' : ''" @click="selectTab(1)">
                            รหัส
                        </div>
                        <div id="tab-2" class="tab-nav-item" :class="tab === 2 ? 'tab-active' : ''" @click="selectTab(2)">
                            QR Code
                        </div>
                    </div>
                    <div class="tab-detail" v-if="tab === 1">
                        <div class="code-text">
                            <div id="copy">{{ formData.code }}</div>
                            <div class="copy-text" style="letter-spacing: 1px" @click="copyURL(formData.code)">
                                <img
                                    src="@/assets/images/coppy.png"
                                    width="20"
                                    alt=""
                                    style="margin-bottom: -6px; letter-spacing: 1px"
                                />
                                คัดลอกรหัส
                            </div>
                            <input style="display: none" id="copyTextarea" />
                        </div>
                        <div class="remark-text">คุณสามารถใช้รหัสภายหลังได้</div>
                    </div>
                    <div class="tab-detail" v-else>
                        <div class="qr-code">
                            <qr-code
                                :text="`https://liff.line.me/${liffId}/scan-qr?qrCode=${formData.code}&userId=${userData._id}`"
                                :size="180"
                                color="#000"
                                bg-color="#FFF"
                                error-level="L"
                            >
                            </qr-code>
                            <!-- <qrcode :background="background" :padding="0" :size="260" :cls="qrCls" :value="`https://liff.line.me/${liffId}/scan-qr?qr-code=${formData.code}&id=${formData.id}&userId=${userProfile.userId}`"></qrcode> -->
                        </div>
                    </div>
                </div>
                <div class="btn-close-modal" @click="modalHide">x</div>
            </v-card>
        </Modal>
        <Modal :visible="showModalConFirm">
            <v-card class="pa-4 text-center">
                <div class="confirm-text">
                    <br />
                    ยืนยันใช้คูปอง
                    <br />
                </div>
                <div class="btn-wp">
                    <div class="btn-modal ok" @click="UseCoupon">ยืนยัน</div>
                    <div class="btn-modal close" @click="closeModelConfirm">ยกเลิก</div>
                </div>
            </v-card>
        </Modal>
        <Modal :visible="showModalError">
            <v-card class="pa-4 text-center">
                <div class="confirm-text">
                    <br />
                    {{ errorMessage }}
                    <br />
                </div>
                <div class="btn-wp">
                    <div class="btn-modals confirm-btn" @click="closeError">ตกลง</div>
                </div>
            </v-card>
        </Modal>
    </layout-user>
</template>

<script>
import { getCouponAPI, checkCouponAPI, useCouponAPI } from "@/apis/user/coupon";
import moment from "moment";
export default {
    components: {},
    data() {
        const userProfile = this.$cookies.get("LINE_LIFF_DATA");
        const userData = this.$cookies.get("USER_DATA");
        return {
            qrCls: "qrcode",
            qrText: "Alcon QR Code Coupon",
            size: 260,
            padding: 0,
            background: "#FFF",
            userData,
            userProfile,
            loading: true,
            display: true,
            buttonDirty: false,
            formDirty: false,
            formData: {
                code: "",
                condition: "",
                count_use: 0,
                created_at: "",
                deleted_at: "",
                detail: "",
                expire_date: "",
                id: "",
                image_url: "",
                is_active: true,
                is_expire: false,
                is_use: false,
                name: "",
                seq: "",
                start_date: "2022-02-01T00:00:00.001Z",
                updated_at: "2022-02-09T17:12:10.967Z",
            },
            showModal: false,
            showModalConFirm: false,
            showModalError: false,
            errorMessage: "",
            tab: 1,
            qr_ref_id: "",
            liffId: process.env.VUE_APP_LINE_LIFF_ID,
            is_get: false,
            x: null,
            idcoupon: "",
        };
    },
    unmount() {
        console.log("ออก");
    },
    async mounted() {
        if (this.userData === null) {
            this.$router.push("/");
        } else {
            if (!this.userData.is_questionnaire) {
                this.$router.push("/user/questionnaire");
            }
        }
        if (this.$route.query.id) {
            this.idcoupon = this.$route.query.id;
            await this.getData(this.$route.query.id);
            // if (this.$route.query.id && !this.t) { // it seems to me this additional check would make sense?
            this.x = setInterval(() => {
                // location.reload()

                console.log(this.$route.query.id);
                this.getRealTime(this.$route.query.id);
            }, 60000);
            // } else {
            //     clearInterval(this.t)
            // }
        } else {
            this.$router.push("/user/home");
        }
        console.log(this.userData._id);
        this.display = true;
        this.loading = false;
    },
    beforeDestroy() {
        console.log(this.x);
        clearInterval(this.x);
        clearInterval(this.getdata_realtime);
    },
    destroyed() {},

    methods: {
        async getData(id) {
            this.loading = true;
            await getCouponAPI({
                mid: id,
                cbSuccess: (res) => {
                    if (res.status === 200) {
                        if (res.data.data !== null && res.data.data !== undefined) {
                            this.formData = res.data.data;
                            if (!res.data.data.is_active) {
                                this.errorMessage = "ไม่สามารถใช้งานคูปองนี้ได้";
                                this.showModalError = true;
                            }
                            if (res.data.data.is_expire) {
                                this.errorMessage = "คูปองหมดอายุ";
                                this.showModalError = true;
                            }
                            this.checkUserCoupon();
                        } else {
                            this.$router.push("/user/home");
                        }
                    }

                    this.loading = false;
                },
                cbError: (err) => {
                    if (err) {
                        console.log("err:", err);
                    }
                    this.loading = false;
                },
            });
        },
        async getRealTime(id) {
            let status = false;
            await getCouponAPI({
                mid: id,
                cbSuccess: (res) => {
                    if (res.status === 200) {
                        if (!res.data.data.is_active) {
                            this.errorMessage = "ไม่สามารถใช้งานคูปองนี้ได้";
                            this.showModalError = true;
                            status = true;
                        }
                        if (res.data.data.is_expire) {
                            this.errorMessage = "คูปองหมดอายุ";
                            this.showModalError = true;
                            status = true;
                        }
                    }

                    this.loading = false;
                },
                cbError: (err) => {
                    if (err) {
                        console.log("err:", err);
                    }
                    status = true;
                    this.loading = false;
                },
            });
            return status;
        },
        async checkUserCoupon() {
            // this.loading = true;
            let status = false;
            await checkCouponAPI({
                code: this.formData.id,
                mid: this.userProfile.userId,
                cbSuccess: (res) => {
                    if (res.status === 200) {
                        if (res.data.data !== null) {
                            this.is_get = true;
                            this.formData.code = res.data.data.code;
                            this.formData.is_use = res.data.data.is_use;
                            if (res.data.data.is_use) {
                                status = true;
                            }
                        } else {
                            this.is_get = false;
                        }
                    }
                    this.loading = false;
                },
                cbError: (err) => {
                    if (err) {
                        console.log("err:", err);
                    }
                    this.loading = false;
                },
            });
            return status;
        },
        async checkUserCouponRealtime() {
            let status = false;
            await checkCouponAPI({
                code: this.formData.id,
                mid: this.userProfile.userId,
                cbSuccess: (res) => {
                    if (res.status === 200) {
                        if (res.data.data !== null) {
                            this.is_get = true;
                            this.formData.code = res.data.data.code;
                            this.formData.is_use = res.data.data.is_use;
                            if (res.data.data.is_use) {
                                // this.errorMessage = "ใช้คูปองเรียบร้อยแล้ว"
                                // this.showModalError = true
                                clearInterval(this.getdata_realtime);
                                this.$router.push("/user/home");
                            }
                        } else {
                            this.is_get = false;
                        }
                    }
                },
                cbError: (err) => {
                    if (err) {
                        console.log("err:", err);
                    }
                    this.loading = false;
                },
            });
            return status;
        },
        async UseCoupon() {
            console.log();
            this.loading = true;
            await useCouponAPI({
                payload: {
                    user_id: this.userData._id,
                    user_line_id: this.userProfile.userId,
                    user_line_name: this.userProfile.displayName,
                    user_line_image: this.userProfile.pictureUrl,
                    coupon_id: this.formData.id,
                },
                cbSuccess: (res) => {
                    if (res.status === 200) {
                        console.log(res.data);
                        this.checkUserCoupon();
                        this.showModalConFirm = false;
                        this.showModal = true;
                    }
                    this.loading = false;
                },
                cbError: (err) => {
                    if (err) {
                        console.log("err:", err);
                    }
                    this.loading = false;
                },
            });
        },
        dateFormat(date) {
            var $month = "";
            switch (moment.parseZone(date).format("MM")) {
                case "01":
                    $month = "มกราคม";
                    break;
                case "02":
                    $month = "กุมภาพันธ์";
                    break;
                case "03":
                    $month = "มีนาคม";
                    break;
                case "04":
                    $month = "เมษายน";
                    break;
                case "05":
                    $month = "พฤษภาคม";
                    break;
                case "06":
                    $month = "มิถุนายน";
                    break;
                case "07":
                    $month = "กรกฎาคม";
                    break;
                case "08":
                    $month = "สิงหาคม";
                    break;
                case "09":
                    $month = "กันยายน";
                    break;
                case "10":
                    $month = "ตุลาคม";
                    break;
                case "11":
                    $month = "พฤศจิกายน";
                    break;
                case "12":
                    $month = "ธันวาคม";
                    break;
            }

            return moment.parseZone(date).format("DD") + " " + $month;
        },
        dateYear(d) {
             return moment.parseZone(d).format("YYYY");
        },
        handleNewLine(str) {
            return str.replace(/(?:\r\n|\r|\n)/g, '<br />');
        },
        async modalShow() {
            const check_is_active = await this.getRealTime(this.$route.query.id);
            if (!check_is_active) {
                if (this.is_get) {
                    const status = await this.checkUserCoupon();
                    if (status) {
                        this.errorMessage = "คูปองถูกใช้งานแล้ว";
                        this.showModalError = true;
                        clearInterval(this.getdata_realtime);
                        this.$router.push("/user/home");
                    } else {
                        this.getdata_realtime = setInterval(async () => {
                            await this.checkUserCouponRealtime();
                            // await this.checkUserCoupon();
                        }, 8000);
                        this.showModal = true;
                    }
                } else {
                    this.showModalConFirm = true;
                }
            }
        },
        modalShowCode() {
            this.showModal = true;
        },
        modalHide() {
            this.showModal = false;
        },
        closeModelConfirm() {
            this.showModalConFirm = false;
        },
        selectTab(tab) {
            this.tab = tab;
        },
        async copyURL(mytext) {
            try {
                await navigator.clipboard.writeText(mytext);
                alert("Copied");
            } catch ($e) {
                alert("Cannot copy");
            }
        },
        closeError() {
            clearInterval(this.getdata_realtime);
            this.showModalError = false;
            this.$router.push("/user/home");
        },
    },
};
</script>

<style scoped>
p {
    margin-bottom: 5px !important;
}
.v-application p {
    margin-bottom: 0px !important;
}
.content-wrap {
    height: 100%;
    overflow: auto;
    position: relative;
    box-shadow: 0px 0px 10px grey;
    background-image: url("../../../assets/images/COUPON PAGE_DB.png");
    /* background-image: url(/img/bg.c4154c07.png); */
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #082257;
}
.body {
    flex: 1;
    padding: 24px 34px 0;
    z-index: 10;
}
.footer {
    padding: 16px 32px;
    z-index: 10;
    position: relative;
}
.form-title {
    font-size: 50px;
    font-weight: 700;
    color: #002f87;
    text-align: center;
    margin-bottom: 0px;
    text-shadow: 1px 1px 8px #1e4489;
}
.form-input {
    margin-bottom: 10px;
}
.form-input:last-child {
    margin-bottom: 0;
}
.form-label {
    font-size: 30px;
    font-weight: 400;
    color: #f0f6ff;
    margin-bottom: 4px;
    text-shadow: 1px 1px 8px #1e4489;
}
.btn-register {
    text-align: center;
}
.img-btn-register {
    cursor: pointer;
}
.line-display-img2 {
    margin-top: -170px;
    display: flex;
}
.radius-100 {
    border-radius: 100%;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 20%);
}

.vhpage {
    height: 30px;
}

.coupon-img {
    width: 100%;
    border-radius: 16px;
}
#form-gradient {
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0) 100%);
    border-radius: 16px;
    padding-bottom: 10px;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    position: relative;
    color: #194ba0;
    margin-left: -15px;
    margin-right: -15px;
}
.coupon-expire-date {
    font-size: 24px;
    padding: 0 15px;
    margin-bottom: 15px;
}
.coupon-detail {
    font-size: 20px;
    width: 100%;
    padding: 0 15px;
    margin-bottom: 20px;
    font-family: "DB Helvethaica X 67 MedCond";
    font-weight: 100;
}
.coupon-condition {
    font-size: 20px;
    width: 85%;
    padding: 0 15px;
    margin-bottom: 20px;
    font-family: "DB Helvethaica X 67 MedCond";
    font-weight: 100;
}
.talent2 {
    position: absolute;
    right: -15px;
    bottom: 75px;
    width: 130px;
}
.tab-nav {
    display: flex;
}
.tab-nav-item {
    font-family: "DB Helvethaica X 67 MedCond";
    font-weight: 100;
    font-size: 28px;
    width: 50%;
    background: #f0f6ff;
    color: #194ba0;
    padding: 8px;
}
.tab-nav-item.tab-active {
    width: 50%;
    background: #194ba0;
    color: #fff;
}
#tab-1 {
    border-top-left-radius: 16px;
}
#tab-2 {
    border-top-right-radius: 16px;
}
.btn-close-modal {
    font-family: "DB Helvethaica X 67 MedCond";
    font-weight: 100;
    font-size: 28px;
    background: #c7cfdb;
    color: #194ba0;
    font-size: 36px;
    cursor: pointer;
}
.tab-detail {
    padding: 25px;
}
.v-sheet.v-card {
    border-radius: 16px;
    background: #dfe3ea;
}
.code-text {
    font-family: "DB Helvethaica X 67 MedCond";
    font-weight: 600;
    color: #194ba0;
    font-size: 40px;
    text-align: center;
    padding: 20px 0;
    letter-spacing: 5px;
}
.qrcode {
    background: #fff;
    padding: 5% 0px;
}
.qrCls {
    margin: 0 auto;
}
.copy-text {
    font-family: "DB Helvethaica X 67 MedCond";
    font-weight: 100;
    font-size: 24px;
    width: fit-content;
    margin: 0 auto;
    padding: 5px 15px;
    background: #b9c4d2;
    cursor: pointer;
    margin-top: 10px;
}
.remark-text {
    font-family: "DB Helvethaica X 67 MedCond";
    font-weight: 100;
    text-align: center;
    font-size: 22px;
    color: #194ba0;
}
.confirm-text {
    width: 100%;
    text-align: center;
    font-family: "DB Helvethaica X 67 MedCond";
    font-weight: 100;
    text-align: center;
    font-size: 34px;
    color: #194ba0;
}
.btn-wp {
    font-family: "DB Helvethaica X 67 MedCond";
    font-size: 28px;
    font-weight: 100;
    display: flex;
    width: 100%;
}
.btn-modal {
    width: 50%;
    margin: 5px;
}
.btn-modal.ok {
    font-family: "DB Helvethaica X 67 MedCond";
    font-size: 28px;
    font-weight: 100;
    background: #194ba0;
    color: #fff;
    border-bottom-left-radius: 15px;
    padding: 10px;
    cursor: pointer;
}
.btn-modals.confirm-btn {
    font-family: "DB Helvethaica X 67 MedCond";
    font-size: 28px;
    font-weight: 100;
    width: 100%;
    background: #194ba0;
    color: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    padding: 10px;
    cursor: pointer;
}
.btn-modal.close {
    font-family: "DB Helvethaica X 67 MedCond";
    font-size: 28px;
    font-weight: 100;
    background: red;
    color: #fff;
    border-bottom-right-radius: 15px;
    padding: 10px;
    cursor: pointer;
}
.confirm-text {
    margin-bottom: 60px;
}
.qr-code {
    margin: 0 auto;
    text-align: center;
    width: fit-content;
}
.img-profile2 {
    width: 70px;
    margin-right: 20px;
}
.name-profile2 {
    font-family: "DB Helvethaica X 67 MedCond";
    font-weight: 100;
    font-size: 25px;
    margin-top: 5px;
    color: #fff;
    width: 250px;
    text-overflow: ellipsis;
    /* overflow: hidden; */
    white-space: nowrap;
    line-height: 25px;
}
.btn-flex {
    display: flex;
}
.btn-50per {
    width: 50%;
}
.btn-50per > img {
    width: 100%;
}
@media only screen and (max-width: 320px) {
    .img-profile2 {
        width: 55px;
        margin-right: 12px;
    }
    .name-profile2 {
        margin-top: 0px;
        font-size: 20px;
        width: 160px;
    }
}
@media only screen and (min-width: 1200px) {
}
/* iPhone 13 mini */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .name-profile2 {
        font-size: 28px;
    }
}
/* iPhone 13 and iPhone 13 Pro */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    .img-profile2 {
        width: 70px;
        margin-right: 12px;
    }
    .name-profile2 {
        margin-top: 0px;

        font-size: 30px;
        width: 240px;
    }
}
/* iPhone 13 Pro Max */
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
}
/* iPhone 11 and iPhone XR */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
}
/* iPhone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
}
/* iPhone 11 Pro Max and iPhone Xs Max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
}
</style>
