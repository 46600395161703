<template>
    <loading v-if="!display"/>
    <layout-store
        v-else
        pageTitle=""
        active=""
        backPath=""
        :hideBackButton="true"
        hideFooterBackground
    >
        <loading v-if="loading"/>
        <div class="body">
            <div class="form-title"></div>
              <div class="welcome-text">
                <div style="font-size:36px;">
                    ยินดีต้อนรับ
                </div> 
                <div style="white-space: pre-wrap;">
                   {{adminData.store_name}}
                </div>     
                <div>
                    เข้าสู่ระบบ
                </div>
            </div>  
        </div>
        <div class="footer">
            <!-- <BaseButton
                label="ถัดไป"
                @onClick="onSubmit"
                full
                :disabled="true"
            /> -->
            <div class="btn-fix-bottom">
                <div class="btn-next">
                    <img src="@/assets/store/images/btn-next-active.png" width="220"  alt=""   @click="onSubmit"  srcset="">
                </div>
            </div>
            
        </div>
    
    </layout-store>
</template>
<script>
export default {
    data() {
        const userProfile = this.$cookies.get("LINE_LIFF_DATA")
        const userData = this.$cookies.get("USER_DATA")
        const adminData = this.$cookies.get("ADMIN_DATA")
        return {
            userProfile,
            adminData,
            loading: true,
            display: true,
            buttonDirty: false,
            formDirty: false,
            formData: {},
        }
    },mounted() {
        if (this.adminData === null) {
            this.$router.push(`/404`)
        }
        this.loading = false
    },
    methods:{
        onSubmit() {
            this.$router.push('/admin/use-coupon')
        }
    }
}
</script>
<style>
.welcome-text {
  font-family: 'DB Helvethaica X 77 BdCond';
    text-align: center;
    font-size: 30px;
    color: #1c4287;
    font-weight: 900;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 40px;
    white-space: pre-wrap;
}
</style>