import { service } from "../axios"

const request = async(instance , { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if(res && res.status === 200 ) {
            cbSuccess(res)
        } else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e)
        cbError(e)
    }
}

export const getCouponAPI = ({ mid, cbSuccess , cbError }) => request(
    service.get(`/api/master-coupon/detail/${mid}`) ,
    { cbSuccess , cbError }
)

export const couponHistoryAPI = ({ payload, cbSuccess , cbError }) => request(
    service.post(`/api/user-coupon/list`, payload) ,
    { cbSuccess , cbError }
)
export const userCouponQRCCODE = ({ payload, cbSuccess , cbError }) => request(
    service.post(`/api/user-coupon/use/coupon`, payload) ,
    { cbSuccess , cbError }
)
