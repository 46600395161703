<template>
    <loading v-if="!display" />
    <layout-user v-else pageTitle="" active="questionnaire" backPath="/" hideFooterBackground :hideBackButton="true">
        <loading v-if="loading" />
        <div class="body">
            <div class="line-display-img2">
                <div class="img-profile2">
                    <img :src="userProfile.pictureUrl" class="w-100 radius-100" alt="" srcset="" />
                </div>
                <div class="name-profile2">สวัสดี  <div>{{ userProfile.displayName }}</div></div>
            </div>
            <div class="vhpage"></div>
            <!-- <div class="form-title fc-2">My Coupon</div> -->
            <div class="form-1">
                <div class="form-input">
                    <div class="form-label">1.ผลิตภัณฑ์ใดของ Alcon ที่คุณสนใจ</div>
                    <div class="qtn-option">
                        <img
                            src="@/assets/images/Freshlook1.png"
                            class="img-qtn border-select"
                            alt=""
                            srcset=""
                            v-if="formData.questionnaire_1 === 'Freshlook'"
                            @click="selectOne('Freshlook')"
                        />

                        <img
                            v-else
                            src="@/assets/images/Freshlook.png"
                            class="img-qtn"
                            alt=""
                            srcset=""
                            @click="selectOne('Freshlook')"
                        />
                        <img
                            src="@/assets/images/Air optix1.png"
                            class="img-qtn border-select"
                            alt=""
                            srcset=""
                            v-if="formData.questionnaire_1 === 'Daillies AquaComfort Plus'"
                            @click="selectOne('Daillies AquaComfort Plus')"
                        />
                        <img
                            v-else
                            src="@/assets/images/Air optix.png"
                            class="img-qtn"
                            alt=""
                            srcset=""
                            @click="selectOne('Daillies AquaComfort Plus')"
                        />
                        <img
                            v-if="formData.questionnaire_1 === 'PRECISION'"
                            src="@/assets/images/PRECISION 11.png"
                            class="img-qtn border-select"
                            alt=""
                            srcset=""
                            @click="selectOne('PRECISION')"
                        />
                        <img
                            v-else
                            src="@/assets/images/PRECISION 1.png"
                            class="img-qtn"
                            alt=""
                            srcset=""
                            @click="selectOne('PRECISION')"
                        />
                        <img
                            v-if="formData.questionnaire_1 === 'Air optix'"
                            src="@/assets/images/airoptix1.png"
                            class="img-qtn border-select"
                            alt=""
                            srcset=""
                            @click="selectOne('Air optix')"
                        />
                        <img
                            v-else
                            src="@/assets/images/Air optix2.png"
                            class="img-qtn"
                            alt=""
                            srcset=""
                            @click="selectOne('Air optix')"
                        />
                        <img
                            v-if="formData.questionnaire_1 === 'OPTI FREE'"
                            src="@/assets/images/OPTI FREE111.png"
                            class="img-qtn border-select"
                            alt=""
                            srcset=""
                            @click="selectOne('OPTI FREE')"
                        />
                        <img
                            v-else
                            src="@/assets/images/OPTI FREE.png"
                            class="img-qtn"
                            alt=""
                            srcset=""
                            @click="selectOne('OPTI FREE')"
                        />
                    </div>
                </div>
                <div class="form-input">
                    <div class="form-label">2.ผลิตภัณฑ์ใดที่คุณใช้อยู่ปัจจุบัน</div>
                    <!-- <base-input v-model="formData.questionnaire_2" placeholder="" type="text" /> -->
                    <select class="select-custom" v-model="formData.questionnaire_2" name="" id="">
                        <option disabled value=""></option>
                        <option v-for="(v, i) in option_question_1" :key="i" :value="v">{{ v }}</option>
                    </select>
                </div>
                <div class="form-input">
                    <div class="form-label">3.คุณใช้ผลิตภัณฑ์นี้บ่อยแค่ไหน</div>
                    <!-- <base-input v-model="formData.questionnaire_3" placeholder="" type="text" /> -->
                    <select class="select-custom" v-model="formData.questionnaire_3" name="" id="">
                        <option disabled value=""></option>
                        <option v-for="(v, i) in option_question_2" :key="i" :value="v">{{ v }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="btn-register">
                <img
                    v-if="true"
                    @click="updateQuestionnaire"
                    src="@/assets/images/btn-next-active.png"
                    class="img-btn-register btn-w250"
                    alt=""
                />
                <img v-else src="@/assets/images/btn-next-disable.png" class="img-btn-register btn-w250" alt="" />
            </div>
        </div>
        <Modal :visible="showDialog">
            <v-card class="pa-4 text-center">
                <div class="mb-1 font-weight-2 font-size-4 mb-4 fc-1 modal-text-detail">{{ messageError }}</div>
                <div class="btn-close-modal" @click="onDialogSubmit">ตกลง</div>
            </v-card>
        </Modal>
    </layout-user>
</template>

<script>
import { getUserProfileAPI, updateUserProfileAPI, checkBADataAPI, registerAPI } from "@/apis/user/register";

export default {
    data() {
        const userProfile = this.$cookies.get("LINE_LIFF_DATA");
        const userData = this.$cookies.get("USER_DATA");
        return {
            userProfile,
            userData,
            loading: true,
            display: true,
            buttonDirty: false,
            formDirty: false,
            formData: {
                ...userData,
            },
            showDialog: false,
            messageError: "",
            option_question_1: [
                "Bausch and Lomb Soflens รายเดือน",
                "Bausch and Lomb Soflens รายวัน",
                "Bausch and Lomb Ultra",
                "Acuvue Moist",
                "Acuvue Oasys",
                "Acuvue Define",
                "Maxim คอนแทคเลนส์สี",
                "Maxim คอนแทคเลนส์ใส",
                "Duna คอนแทคเลนส์ ",
            ],
            option_question_2: ["ใช้ทุกวัน", "วันเว้นวัน", "อาทิตย์ละ 2 วัน", "อาทิตย์ละ 3 วัน", "เดือนละครั้ง"],
        };
    },
    mounted() {
        if (this.userData === null) {
            this.$router.push("/");
        } else {
            if (this.userData.is_questionnaire) {
                this.$router.push("/user/home");
            }
        }
        this.display = true;
        this.loading = false;
    },

    methods: {
        async updateQuestionnaire() {
            if (
                this.formData.questionnaire_1 === "" ||
                this.formData.questionnaire_2 === "" ||
                this.formData.questionnaire_3 === ""
            ) {
                this.messageError = "กรุณาเลือกคำตอบให้ครบทุกข้อ";
                this.showDialog = true;
            } else {
                this.formData.is_questionnaire = true;
                const formData = {
                    ...this.formData,
                };
                await updateUserProfileAPI({
                    payload: formData,
                    cbSuccess: async (res) => {
                        if (res.data.status === "UPDATE_SUCCESS") {
                            this.$router.push("/");
                        } else {
                            this.messageError = "ทำรายการไม่สำเร็จ";
                            this.showDialog = true;
                            this.loading = false;
                        }
                    },
                    cbError: (e) => {
                        console.log("e", e);
                    },
                });
            }
        },
        selectOne(text) {
            this.formData.questionnaire_1 = text;
        },
        onDialogSubmit() {
            this.showDialog = false;
            this.buttonDirty = false;
        },
    },
};
</script>

<style scoped>
.content-wrap {
    height: 100%;
    overflow: auto;
    position: relative;
    box-shadow: 0px 0px 10px grey;
    background-image: url("../../../assets/images/COUPON PAGE_DB.png");
    /* background-image: url(/img/bg.c4154c07.png); */
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #082257;
}
.body {
    flex: 1;
    padding: 24px 34px 0;
    z-index: 10;
}
.footer {
    padding: 16px 32px;
    z-index: 10;
    position: relative;
}
.form-title {
    font-size: 36px;
    font-weight: 700;
    color: #002f87;
    text-align: center;
    margin-bottom: 0px;
    text-shadow: 1px 1px 8px #1e4489;
}
.form-input {
    margin-bottom: 10px;
}
.form-input:last-child {
    margin-bottom: 0;
}
.form-label {
    font-family: 'DB Helvethaica X 67 MedCond';
    font-weight: 100;
    font-size: 26px;
    font-weight: 400;
    color: #f0f6ff;
    margin-bottom: 4px;
    text-shadow: 1px 1px 8px #1e4489;
}
.btn-register {
    text-align: center;
}
.img-btn-register {
    cursor: pointer;
}
.line-display-img2 {
    margin-top: -170px;
    display: flex;
}
.radius-100 {
    border-radius: 100%;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 20%);
}


.vhpage {
    height: 30px;
}
.qtn-option {
    text-align: center;
}
.btn-close-modal {
    font-family: 'DB Helvethaica X 67 MedCond';
    font-weight: 100;
    background: #c7cfdb;
    color: #194ba0;
    font-size: 28px;
    cursor: pointer;
}

.text-term-label {
    font-family: "DB Helvethaica X 67 MedCond";
    font-size: 24px;
    font-weight: 400;
    color: #f0f6ff;
    margin-bottom: 4px;
    text-shadow: 1px 1px 8px #1e4489;
}
.modal-text-detail {
    padding: 20px 0;
    font-family: "DB Helvethaica X 67 MedCond";
    font-size: 34px;
    font-weight: 400;
    color: #1e4489;
}
.select-custom {
    font-family: "DB Helvethaica X 67 MedCond";
    font-weight: 100;
    width: 100%;
    width: 100%;
    height: 40px;
    box-shadow: 1px 1px 5px solid #265599;
    background-color: #f0f6ff;
    border-radius: 8px;
    font-weight: 700;
    font-size: 18px;
    color: #1c4287;
    padding: 0 16px;
    line-height: 30px;
    text-indent: 1px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 8px;
}
.border-select {
    border: 2px solid #204992;
    border-radius: 8px;
    padding-top: 5px;
    
}
.img-qtn {
    width: 250px;
}
.img-profile2 {
    width: 70px;
    margin-right: 20px;
}
.name-profile2 {
    font-family: "DB Helvethaica X 67 MedCond";
    font-weight: 100;
    font-size: 25px;
    margin-top: 5px;
    color: #fff;
    width: 250px;
    text-overflow: ellipsis;
    /* overflow: hidden; */
    white-space: nowrap;
    line-height: 25px;
}
@media only screen and (max-width: 320px) {
    .img-profile2 {
        width: 55px;
        margin-right: 12px;
    }
    .name-profile2 {
       margin-top: 0px;
        font-size: 20px;
        width: 160px;
    }
}
@media only screen and (min-width: 1200px) {
}
/* iPhone 13 mini */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .name-profile2 {
        font-size: 28px;
    }
}
/* iPhone 13 and iPhone 13 Pro */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
       .img-profile2 {
        width: 70px;
        margin-right: 12px;
    }
    .name-profile2 {
        margin-top: 0px;

        font-size: 30px;
        width: 240px;
    }
}
/* iPhone 13 Pro Max */
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
}
/* iPhone 11 and iPhone XR */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
}
/* iPhone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
}
/* iPhone 11 Pro Max and iPhone Xs Max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
}
</style>
