import axios from "axios"

export const service = axios.create({
    baseURL : process.env.VUE_APP_API_END_POINT,
    timeout : 30000
})

service.interceptors.request.use(
    config => {
        config.headers.Authorization = `Bearer ${ process.env.VUE_APP_TOKEN }`;
        return config
    } ,
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

