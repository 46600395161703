<template>
    <span>
        <loading v-if="loading"/> 
            <Modal :visible="showDialog">
            <v-card class="pa-4 text-center">
                <div class="mb-1 font-weight-2 font-size-4 mb-4 fc-1 modal-text-detail" >{{messageError}}</div>
                 <div class="btn-close-modal" @click="onDialogSubmit">
                  ตกลง
              </div>
            </v-card>
        </Modal>
    </span>
</template>
<script>
import { checkRegister , registerAdmin,checkMIDRegister } from '../../apis/store/adminregister'
import { getUserProfileAPI } from '../../apis/user/register'
export default {
   data() {
        const userProfile = this.$cookies.get("LINE_LIFF_DATA")
        const userData = this.$cookies.get("USER_DATA")
        const adminData = this.$cookies.get("ADMIN_DATA")
        return {
            userProfile,
            userData,
            adminData,
            loading: true,
            display: true,
            buttonDirty: false,
            formDirty: false,
            formData: {},
            showDialog:false,
            messageError:""
            
        }
    },
    async mounted() {
        try {
            this.loading = true
            const qrcode = this.$route.query.qrcode
            console.log(qrcode);
            if (qrcode !== undefined) {
                // store
              const checkMID =  await this.firstcheckAdminRegister(this.userProfile.userId)
              console.log(checkMID)
                if (checkMID !== null) {
                    if (checkMID.store_id !== qrcode) {
                        // console.log("ลงทะเบียน QR Code นี้ไปแล้ว")
                        this.alertMessage(`ท่านได้ลงทะเบียนกับร้าน ${checkMID.store_name} เรียบร้อยแล้ว`)
                    } else {
                        await this.AdminRegister(qrcode)

                    }
                } else {

                    await this.AdminRegister(qrcode)

                }
            } else {
                // user
                console.log(this.userData)
                await this.GetUserData(this.userProfile.userId)

            }
        } catch (error) {
           this.alertMessage(error)
        }
        
    },
    methods:{
        async firstcheckAdminRegister(line_user_id) {
            let data = null
            await checkMIDRegister({
                mid: line_user_id,
                cbSuccess : async res => { 
                   data = res.data.data
                //    this.$cookies.set("ADMIN_DATA",JSON.stringify(data))
                // if
                } ,
                cbError : (e) => {
                    console.log('e', e)
                    this.alertMessage(e)
                }
            })
            return data
        },
        async checkAdminRegister(line_user_id,store_id) {
            let data = null
            await checkRegister({
                mid: line_user_id,qr:store_id,
                cbSuccess : async res => { 
                   data = res.data.data
                   this.$cookies.set("ADMIN_DATA",JSON.stringify(data))
                } ,
                cbError : (e) => {
                    console.log('e', e)
                    this.alertMessage(e)
                }
            })
            return data
        },
        async AdminRegister(store_id) {
            let data = null
            await registerAdmin({
                payload: {
                    line_user_id : this.userProfile.userId,
                    line_display_name: this.userProfile.displayName,
                    line_display_image:this.userProfile.pictureUrl,
                    store_id: store_id
                },
                cbSuccess : async res => { 
                    if (res.data.status === 'CREATE_SUCCESS') {
                        await this.checkAdminRegister(this.userProfile.userId,store_id)
                        // this.$router.push(`/admin/term`)
                        this.$router.push("/admin/welcome");

                    } else if (res.data.status === 'UPDATE_SUCCESS') {
                        await this.checkAdminRegister(this.userProfile.userId,store_id)
                        this.$router.push(`/admin/use-coupon`)
                    } else if (res.data.status === 'QRCODE_NOT_FOUND') {
                        this.alertMessage("QR Code ไม่ถูกต้อง")
                    } else if (res.data.status === 'QRCODE_IS_USED') {
                        this.alertMessage("QR Code ถูกใช้งานแล้ว")
                    } else {
                        this.alertMessage(res.data.status)
                    }

                } ,
                cbError : (e) => {
                    console.log('e', e)
                    this.alertMessage(e)
                }
            })
            return data
        },
        async alertMessage(msg) {
            // alert(msg)
            this.loading = false
            this.messageError = msg
            this.showDialog = true
        },
        async GetUserData(line_user_id) {
            let data = null
            await getUserProfileAPI({
                mid: line_user_id,
                cbSuccess : async res => { 
                    data = res.data.data
                    if (data === null) {
                        this.$router.push(`/user/term`)
                    } else {
                        this.$cookies.set("USER_DATA",JSON.stringify(data))
                        if (data.is_questionnaire) {
                            this.$router.push(`/user/home`)
                        } else {
                            this.$router.push(`/user/questionnaire`)
                        }
                        
                    }             
                },
                cbError : (e) => {
                    console.log('e', e)
                    this.alertMessage(e)
                }
            })
            return data
        },
        onDialogSubmit() {
            this.showDialog = false
            liff.closeWindow()
            this.$router.push(`/404`)
        }
    }


}
</script>
<style scoped>
.btn-close-modal {
    font-family: 'DB Helvethaica X 67 MedCond';
    font-weight: 100;
    background: #c7cfdb;
    color: #194ba0;
    font-size: 28px;
    cursor: pointer;
}
.modal-text-detail {
    padding: 20px 0;
    font-family: 'DB Helvethaica X 67 MedCond';
    font-size: 34px;
    font-weight: 400;
    color: #1e4489;
}
</style>