import { service } from "../axios"

const request = async(instance , { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if(res && res.status === 200 ) {
            cbSuccess(res)
        } else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e)
        cbError(e)
    }
}

export const getUserProfileAPI = ({ mid, cbSuccess , cbError }) => request(
    service.get(`/api/register/detail/${mid}`) ,
    { cbSuccess , cbError }
)

export const registerAPI = ({ payload, cbSuccess , cbError }) => request(
    service.post(`/api/register/new`, payload) ,
    { cbSuccess , cbError }
)
export const updateLineRegisterProfileAPI = ({ payload, cbSuccess , cbError }) => request(
    service.put(`/api/register/line-update/${payload.line_user_id}`, payload) ,
    { cbSuccess , cbError }
)
export const updateLineProfileAPI = ({ payload, cbSuccess , cbError }) => request(
    service.post(`/api/line-user/update`, payload) ,
    { cbSuccess , cbError }
)

export const updateUserProfileAPI = ({ payload, cbSuccess , cbError }) => request(
    service.put(`/api/register/edit/${payload._id}`, payload) ,
    { cbSuccess , cbError }
)

export const clearUserDataAPI = ({ mid, cbSuccess , cbError }) => request(
    service.get(`/api/app/reset/transaction/${mid}`) ,
    { cbSuccess , cbError }
)



