<template>
    <loading v-if="!display" />
    <layout-user v-else pageTitle="" active="Coupon Detail" backPath="/" hideFooterBackground :hideBackButton="true">
        <loading v-if="loading" />
        <div class="body">
            <div class="line-display-img2">
                <div class="img-profile2">
                    <img :src="userProfile.pictureUrl" class="w-100 radius-100" alt="" srcset="" />
                </div>
                <div class="name-profile2">
                    สวัสดี
                    <div>{{ userProfile.displayName }}</div>
                </div>
            </div>
            <div class="vhpage"></div>
            <div class="form-title fc-2">ร้านค้าที่ร่วมรายการ</div>
            <div class="mg-top-15"></div>
            <div class="form-1"  id="store">
                <div class="form-input">
                    <div class="d-flex heat-tablse">
                          <div class="text-center" style="width:20%;" >
                       No.
                       </div>
                       <div style="width:80%;" class="text-center">Store Name</div>
                    </div>
                 
                                
                 
                    <div id="store-list">
                        <table>
                            <tbody>
                                <tr v-for="(v, i) in tableData.rows" :key="i">
                                    <td style="width:20%;" scope="row">{{ i }}</td>
                                    <td>{{ v.store_name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- <img src="@/assets/images/Group 1.png" class="coupon-img is_use" alt="">
                    <img @click="$router.push(`/user/coupon-detail`)" src="@/assets/images/Group 2.png" class="coupon-img" alt=""> -->
                </div>
            </div>
        </div>
                 <div class="mg-top-15"></div>
   <div class="btn-bottom">
                <div class="btn-next">
                    <img src="@/assets/store/images/btn-back-active.png" alt="" width="220"   @click="$router.back()"  srcset="">
                </div>
            </div>
    </layout-user>
</template>

<script>
import { ApiStoreList } from "@/apis/user/coupon";

export default {
    data() {
        const userProfile = this.$cookies.get("LINE_LIFF_DATA");
        const userData = this.$cookies.get("USER_DATA");
        return {
            userData,
            userProfile,
            loading: true,
            display: true,
            buttonDirty: false,
            formDirty: false,
            formData: {
                sort: "seq",
                order: "desc",
                offset: 0,
                limit: 999999,
                is_export: false,
                filter: {
                    line_user_id: userProfile.userId,
                    start_date: "1970-01-01T00:00:00.001Z",
                    end_data: "1970-01-01T00:00:00.001Z",
                },
            },
            tableData: {
                rows: [],
                total: 0,
            },
        };
    },
    async mounted() {
        if (this.userData === null) {
            this.$router.push("/");
        } else {
            if (!this.userData.is_questionnaire) {
                this.$router.push("/user/questionnaire");
            }
        }
        await this.getList();
    },

    methods: {
        async getList() {
            this.loading = true;
            await ApiStoreList({
                payload: this.formData,
                cbSuccess: (res) => {
                    if (res.status === 200) {
                        this.tableData.rows = res.data.rows;
                        this.tableData.total = res.data.total;
                    }
                },
                cbError: (err) => {
                    if (err) {
                        console.log("err:", err);
                    }
                },
            });
            await setTimeout(() => {
                this.display = true;
                this.loading = false;
            }, 1500);
        },
    },
};
</script>

<style scoped>
.content-wrap {
    height: 100%;
    overflow: auto;
    position: relative;
    box-shadow: 0px 0px 10px grey;
    background-image: url("../../../assets/images/COUPON PAGE_DB.png");
    /* background-image: url(/img/bg.c4154c07.png); */
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #082257;
}
.body {
    flex: 1;
    padding: 24px 34px 0;
    z-index: 10;
}
.footer {
    padding: 16px 32px;
    z-index: 10;
    position: relative;
}
.form-title {
    font-size: 50px;
    font-weight: 700;
    color: #002f87;
    text-align: center;
    margin-bottom: 0px;
    text-shadow: 1px 1px 8px #1e4489;
}
.form-input {
    margin-bottom: 10px;
}
.form-input:last-child {
    margin-bottom: 0;
}
.form-label {
    font-size: 30px;
    font-weight: 400;
    color: #f0f6ff;
    margin-bottom: 4px;
    text-shadow: 1px 1px 8px #1e4489;
}
.btn-register {
    text-align: center;
}
.img-btn-register {
    cursor: pointer;
}
.line-display-img2 {
    margin-top: -170px;
    display: flex;
}
.radius-100 {
    border-radius: 100%;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 20%);
}

.vhpage {
    height: 30px;
}
.mg-top-20 {
    margin-top: 20px;
}
.coupon-img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 25px;
    cursor: pointer;
}
.coupon-img.is_use {
    width: 100%;
    border-radius: 16px;
    filter: grayscale(100%);
}
.coupon-item {
    position: relative;
}
.coupon-over {
    position: absolute;
    left: 50%;
    top: 45%;
    width: 110px;
    transform: translate(-50%, -50%);
}
.font-name-res {
    font-size: 28px !important;
}
.img-profile2 {
    width: 70px;
    margin-right: 20px;
}
.name-profile2 {
    font-family: "DB Helvethaica X 67 MedCond";
    font-weight: 100;
    font-size: 25px;
    margin-top: 5px;
    color: #fff;
    width: 250px;
    text-overflow: ellipsis;
    /* overflow: hidden; */
    white-space: nowrap;
    line-height: 25px;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    color: #1c4287;
    
}
thead {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: none;
}

th {
    /* background: #1c4287; */
    font-size: 36px;
    padding: 0px;
    color: #fff;
}

td {
     font-family: "DB Helvethaica X 67 MedCond";
    font-weight: 100;
     text-align: center;
    padding: 5px;
    font-size: 25px;
}

tr:nth-child(even) {
    background-color: #e1ebf6;
}
.heat-tablse {
    width: 100%;
    padding: 5px;
        font-family: "DB Helvethaica X 67 MedCond";
    font-weight: 100;
    font-size: 30px;
    color: #fff;
      border-top-left-radius: 8px;
    border-top-right-radius: 8px;
     background: #1c4287; 
}
#store {
        border-radius: 8px;
            background: #f6faff;
}
#store-list {
    height: calc(var(--vh, 1vh) * 55);
    overflow: overlay;
    box-shadow: 1px 1px 5px #27529a;
    padding-bottom: 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
@media only screen and (max-width: 320px) {
    .img-profile2 {
        width: 55px;
        margin-right: 12px;
    }
    .name-profile2 {
        margin-top: 0px;
        font-size: 20px;
        width: 160px;
    }
}
@media only screen and (min-width: 1200px) {
}
/* iPhone 13 mini */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .name-profile2 {
        font-size: 28px;
    }
}
/* iPhone 13 and iPhone 13 Pro */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    .img-profile2 {
        width: 70px;
        margin-right: 12px;
    }
    .name-profile2 {
        margin-top: 0px;

        font-size: 30px;
        width: 240px;
    }
}
/* iPhone 13 Pro Max */
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
}
/* iPhone 11 and iPhone XR */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
}
/* iPhone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
}
/* iPhone 11 Pro Max and iPhone Xs Max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
}
</style>
