import { service } from "../axios"

const request = async(instance , { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if(res && res.status === 200 ) {
            cbSuccess(res)
        } else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e)
        cbError(e)
    }
}

export const checkRegister = ({ mid,qr, cbSuccess , cbError }) => request(
    service.get(`/api/admin-store/check-register/${mid}/${qr}`) ,
    { cbSuccess , cbError }
)
export const checkMIDRegister = ({ mid, cbSuccess , cbError }) => request(
    service.get(`/api/admin-store/check-mid/${mid}`) ,
    { cbSuccess , cbError }
)

export const registerAdmin = ({ payload, cbSuccess , cbError }) => request(
    service.post(`/api/admin-store/register`,payload) ,
    { cbSuccess , cbError }
)
