import { service } from "../axios"

const request = async(instance , { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if(res && res.status === 200 ) {
            cbSuccess(res)
        } else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e)
        cbError(e)
    }
}

export const getCouponAPI = ({ mid, cbSuccess , cbError }) => request(
    service.get(`/api/master-coupon/detail/${mid}`) ,
    { cbSuccess , cbError }
)

export const couponListAPI = ({ payload, cbSuccess , cbError }) => request(
    service.post(`/api/master-coupon/app-list`, payload) ,
    { cbSuccess , cbError }
)
export const checkCouponAPI = ({ code,mid, cbSuccess , cbError }) => request(
    service.get(`/api/user-coupon/check-use/${code}/${mid}`) ,
    { cbSuccess , cbError }
)
export const useCouponAPI = ({ payload, cbSuccess , cbError }) => request(
    service.post(`/api/user-coupon/new`,payload) ,
    { cbSuccess , cbError }
)
export const ApiStoreList = ({ payload, cbSuccess , cbError }) => request(
    service.post(`/api/store/list`,payload) ,
    { cbSuccess , cbError }
)
