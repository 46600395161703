<template>
    <loading v-if="!display"/>
    <layout-store
        v-else
        pageTitle=""
        active=""
        backPath=""
        :hideBackButton="true"
        hideFooterBackground
    >
        <loading v-if="loading"/>
        <div class="body">
            <div class="form-title"></div>
              <div class="store-text">
                <div>
                    [Store Name]
                </div>     
            </div>  
        </div>
        <div class="footer">
            <!-- <BaseButton
                label="ถัดไป"
                @onClick="onSubmit"
                full
                :disabled="true"
            /> -->
            <div class="btn-fix-bottom">
                <div class="btn-next">
                    <img src="@/assets/store/images/btn-next-active.png" width="220"  alt=""   @click="onSubmit"  srcset="">
                </div>
            </div>
            
        </div>
    
    </layout-store>
</template>
<script>
export default {
    data() {
        const userProfile = this.$cookies.get("LINE_LIFF_DATA")
        const userData = this.$cookies.get("USER_DATA")
        return {
            userProfile,
            loading: true,
            display: true,
            buttonDirty: false,
            formDirty: false,
            formData: {},
        }
    },mounted() {
        this.loading = false
    },
    methods:{
        onSubmit() {
            this.$router.push('/admin/use-coupon')
        }
    }
}
</script>
<style>
.store-text {
    font-family: 'DB Helvethaica X 77 BdCond';
    text-align: center;
    font-size: 36px;
    color: #1c4287;
    font-weight: 900;

}
</style>